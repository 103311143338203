<div class="contact-details-form stepper-content">
  <main class="stepper-content__body" #stepperContainer>
    <ng-container *ngIf="!isFormSubmissionSuccess && selectedFieldsAndFormDetails">
      <section class="form__data">
        <div class="batch__form__header">
          <div>
            <span class="text-xlg-light batch"> Batch Of {{ batchDetails?.year }} </span>
            <span class="text-sm-light batch"> {{ batchDetails.title }} </span>
          </div>
        </div>
        <div class="form__description">
          <div [innerHTML]="selectedFieldsAndFormDetails?.description"></div>
        </div>
        <div class="form__notification text-sm-light">
          <div class="text-sm-bold">Please Note:</div>
          <div>
            Form end date is
            <span class="text-sm-bold">{{
              selectedFieldsAndFormDetails?.formEndDateTime | date : "d MMM y"
            }}</span>
          </div>
          <div
            *ngIf="
              selectedFieldsAndFormDetails?.miscellaneousOptions[0]
                ?.pledgeCompletionDate
            "
          >
            Pledge completion date is
            <span class="text-sm-bold">{{
              selectedFieldsAndFormDetails?.miscellaneousOptions[0]
                ?.pledgeCompletionDate | date : "d MMM y"
            }}</span>
          </div>
        </div>
        <div class="form__notification text-sm-light">
          <div class="support">
            If you have any queries, please write us at &nbsp;
            <span class="text-sm-bold">{{
              selectedFieldsAndFormDetails?.supportEmail
            }}</span>
          </div>
        </div>
      </section>
      <div class="text-lg-bold">Pledge form</div>
      <iitb-stepper
        (iconEvent)="getStepperIcon($event)"
        #cdkStepper
        [linear]="false"
        [direction]="'vertical'"
        (selectedIndexChange)="handleStepChange($event)"
        [goToStepEnable]="true"
      >
        <cdk-step [optional]="false" [editable]="true">
          <ng-template cdkStepLabel>
            <div class="step-bullet">
              <mat-icon [svgIcon]="stepperIconName?.iconName"></mat-icon>
              <mat-icon
                [svgIcon]="stepperIconName?.iconLine"
                class="step-line"
              ></mat-icon>
            </div>
            <div class="step-title">
              <span class="step-heading">Personal Information</span>
              <span class="step-subheading"
                >Provide personal details for communication purpose</span
              >
            </div>
          </ng-template>
          <ng-container
            *ngIf="selectedFieldsAndFormDetails?.personalDetailsOptions"
          >
            <iitb-personal-details-form
              [isPreviewForm]="false"
              [personalDetails]="
                selectedFieldsAndFormDetails?.personalDetailsOptions
              "
            ></iitb-personal-details-form
          ></ng-container>
        </cdk-step>
        <cdk-step [optional]="false" [editable]="true">
          <ng-template cdkStepLabel>
            <div class="step-bullet">
              <mat-icon [svgIcon]="stepperIconName?.iconName"></mat-icon>
              <mat-icon
                [svgIcon]="stepperIconName?.iconLine"
                class="step-line"
              ></mat-icon>
            </div>
            <div class="step-title">
              <span class="step-heading">Institutional Details</span>
              <span class="step-subheading"
                >Fill your institution details like degree, etc.
              </span>
            </div>
          </ng-template>
          <ng-container
            *ngIf="selectedFieldsAndFormDetails?.institutionalDetailsOptions"
          >
            <iitb-institutional-details-form
              [institutionDetails]="
                selectedFieldsAndFormDetails?.institutionalDetailsOptions
              "
              [isPreviewForm]="false"
            ></iitb-institutional-details-form
          ></ng-container>
        </cdk-step>
        <cdk-step [optional]="false" [editable]="true">
          <ng-template cdkStepLabel>
            <div class="step-bullet">
              <mat-icon [svgIcon]="stepperIconName?.iconName"></mat-icon>
              <mat-icon
                [svgIcon]="stepperIconName?.iconLine"
                class="step-line"
              ></mat-icon>
            </div>
            <div class="step-title">
              <span class="step-heading">Pledge Details</span>
              <span class="step-subheading"
                >Provide details about how you’re pledging</span
              >
            </div>
          </ng-template>
          <ng-container
            *ngIf="selectedFieldsAndFormDetails?.pledgeDetailsOptions"
          >
            <iitb-pledge-details-form
              [pledgeDetails]="
                selectedFieldsAndFormDetails?.pledgeDetailsOptions
              "
              [isPreviewForm]="false"
              [miscellaneousOptions]="
                selectedFieldsAndFormDetails?.miscellaneousOptions
              "
            ></iitb-pledge-details-form>
          </ng-container>
        </cdk-step>
        <cdk-step [optional]="false" [editable]="true">
          <ng-template cdkStepLabel>
            <div class="step-bullet">
              <mat-icon [svgIcon]="stepperIconName?.iconName"></mat-icon>
              <mat-icon
                [svgIcon]="stepperIconName?.iconLine"
                class="step-line"
              ></mat-icon>
            </div>
            <div class="step-title">
              <span class="step-heading">Preview</span>
              <span class="step-subheading">View your filled details</span>
            </div>
          </ng-template>
          <ng-container *ngIf="selectedFieldsAndFormDetails">
            <iitb-form-values-preview
              (onSubmit)="onFormSubmit($event)"
              (onEdit)="gotoEdit($event)"
            ></iitb-form-values-preview>
          </ng-container>
        </cdk-step>
      </iitb-stepper>
    </ng-container>
    <ng-container *ngIf="isFormSubmissionSuccess">
      <section>
        <div class="status-container">
          <h5>Batch of {{ batchDetails.year }} Legacy Project</h5>
          <div>
            <img
              [src]="
                isFormSubmissionSuccess
                  ? 'assets/images/Success.svg'
                  : 'assets/images/Error.svg'
              "
              alt=""
            />
          </div>
          <h6 *ngIf="isFormSubmissionSuccess" class="success">Success!</h6>
          <h6 *ngIf="!isFormSubmissionSuccess" class="error">
            Oh snap! something went wrong.
          </h6>
          <div class="description" *ngIf="isFormSubmissionSuccess; else Error">
            <div>Your form has been successfully submitted!</div>
            <div>We have sent a message to your registered email address.</div>
          </div>
          <ng-template #Error>
            <div class="description">
              <div>An error occurred while submitting your pledge form.</div>
              <div>Please retry or reach out to support if this continues.</div>
            </div>
          </ng-template>
          <div class="support" *ngIf="!isFormSubmissionSuccess">
            <mat-icon svgIcon="email"></mat-icon
            ><span>{{ "givingback@@iitbacr.com" }}</span>
          </div>
          <div class="support" *ngIf="isFormSubmissionSuccess">
            <div class="donation-links" *ngIf="donationLinks && donationLinks.length > 0;">
              <span *ngFor="let donationLink of donationLinks" class="text-sm-bold">
                Donation Link ({{donationLink.abbr}}): <a href="{{ donationLink.link }}" >{{ donationLink.link }}</a>
              </span>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
    <ng-container *ngIf="!otherFormData['status'] && !selectedFieldsAndFormDetails">
      <div class="form__other">
        <h1 class="text-lg-medium" *ngIf="formType !== 'ONGOING' && formType !== 'ARCHIVED'">
          Form is not active!
        </h1>
        <h1 class="text-lg-medium" *ngIf="formType === 'ARCHIVED'">
          This pledge form has been archived!
        </h1>
        <span class="text-sm-ligth" *ngIf="otherFormData?.formStartDateTime && formType === 'SCHEDULED'">
          This form is scheduled and will starts from 
          <span class="text-sm-bold">{{ otherFormData?.formStartDateTime | date : "d MMM y" }}</span>
        </span>
      </div>
    </ng-container>
  </main>

  <footer class="stepper-content__footer"></footer>
</div>
